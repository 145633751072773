import React from "react";
// import ReactDOM from 'react-dom';
import { createStore, compose, applyMiddleware } from "redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import Reducer from "./Redux/Reducer/index";
import { setHeaderToken } from "./Axios/Services";
import Mainroutes from "./Routes/Mainroutes";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

// CREATE STORE
const store = createStore(Reducer, compose(applyMiddleware(thunk)));

// TOKEN AUTHORIZATION

const token = localStorage.getItem("Authtok");
if (token) {
  setHeaderToken(token);
}

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <HelmetProvider>
      <Mainroutes />
    </HelmetProvider>
  </Provider>
);
