import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalLoderAction } from "../../Redux/Action";
import blog1 from "../../assets/image/blog1.jpg";
import blog2 from "../../assets/image/blog2.jpg";
import blog3 from "../../assets/image/blog4.jpg";

function Blog3Detail() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="blog-details-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="blog-details-content">
                <div class="blog-preview-img">
                  <img src={blog1} alt="Blog Details" />
                </div>
                <h2>
                  Exploring the Flavors of Sai Abhi HealthCare: 
                </h2>
                <p style={{textAlign:"justify"}}>
                  The skin around the eyes is extremely sensitive, thin, and
                  delicate compared to other body parts. Changes occurring
                  beneath the deeper layers of the skin often reflect on the
                  surface.
                </p>
                <p style={{textAlign:"justify"}}>
                  Here are a few steps to help you reduce the appearance of dark
                  circles:
                </p>
                <h4 style={{ color: "#6B9246" }}>
                  Eye-Care Regimen and Mask Treatment
                </h4>
                <p style={{textAlign:"justify"}}>
                  A little eye-care goes a long way! While we often take care of
                  our skin, we tend to neglect our eyes. Having an eye-care
                  regimen is essential for beautiful eyes. Start with a regular
                  CTM routine and use Sai Abhi Organic Care's anti-fatigue eye
                  cream massager to de-stress your eye muscles. Additionally,
                  use a rose quartz roller to enhance your de-stressing
                  experience. Once a week, use Sai Abhi Organic Care's Eye-Patch
                  to soothe and rejuvenate your eyes. This practice ensures
                  smooth blood flow and reduces the prominence of dark circles.
                </p>
                <h4 style={{ color: "#6B9246" }}>Good Old Home Remedies</h4>
                <p style={{textAlign:"justify"}}>
                  The best solution is to apply a cold pack or a chilled tea
                  bag. If other options fail, cool a spoonful of tea in the
                  freezer and apply it to the trouble spots. Grind potatoes and
                  apply the paste around your eyes for 5-10 minutes. Repeat this
                  twice or thrice a week for noticeable results. If you prefer
                  DIY remedies, blend a few drops of rose geranium, lavender,
                  and chamomile essential oils with coconut oil. Gently massage
                  it under your eyes to reduce dark circles. A mix of buttermilk
                  and turmeric, applied around the eyes and washed off after 10
                  minutes with cold water, provides quick relief.
                </p>
                <h4 style={{ color: "#6B9246" }}>
                  Identify the Cause and Consider Serum Treatments
                </h4>
                <p style={{textAlign:"justify"}}>
                  First, determine if the dark circles are caused by
                  pigmentation or damage to the underlying capillaries. Press
                  the skin under your eye. If the color remains unchanged, it’s
                  due to pigmentation from genetics or sun exposure. If the skin
                  lightens, it’s due to damage to blood vessels.
                </p>
                <p style={{textAlign:"justify"}}>
                  Discoloration often appears brown or blackish and can be
                  treated with creams containing kojic acid, liquorice,
                  niacinamide, and Vitamin C. We recommend using Sai Abhi
                  Organic Care's Vitamin C serum for the best results.
                </p>{" "}
                <h4 style={{ color: "#6B9246" }}>
                  Tighten Your Sleep Schedule
                </h4>
                <p style={{textAlign:"justify"}}>
                  Netflix and binge-watching have robbed us of our peace and
                  sleep with the anticipation they create. While it gives us an
                  adrenaline rush, it makes our skin look dull and pale.
                  Constant screen exposure deteriorates eye health, weakens the
                  muscles, and results in dark circles. Fixing your sleep
                  schedule is crucial, as it significantly contributes to dark
                  circles. Make sure to get an ideal 8 hours of sleep to
                  rejuvenate your skin and give it the rest it needs.
                </p>
                <h6>
                  These steps can help you say goodbye to dark circles and
                  achieve healthier-looking skin.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Blog3Detail;
