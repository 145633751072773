import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";

function RefundandReturn() {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Refund Policy</h3>
            <ul>
              <li style={{textAlign:"justify"}}>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#000" }}>Refund Policy</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="privacy-policy-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Return & Refund Policy</span>
            </div>
            <h2>Sai Abhi Organic Care Return & Refund Policy</h2>
          </div>
          <div class="row pt-45">
            <div class="col-lg-12">
              <div class="single-content">
                <h3>Cancellation Policy</h3>
                <h6>Customer Cancellations</h6>
                <ul>
                  <li style={{textAlign:"justify"}}>
                    You can cancel your order within 24 hours by contacting us
                    at saiabhiorganiccare.com.
                  </li>
                  <li style={{textAlign:"justify"}}> Orders cannot be canceled after 24 hours.</li>
                </ul>
                <h6>Cancellations by Sai Abhi Organic Care</h6>
                <ul>
                  <li style={{textAlign:"justify"}}>
                    We may cancel orders due to stock issues or suspected fraud.
                    You will be notified, and a full refund will be issued.
                  </li>
                </ul>
              </div>
              <div class="single-content">
                <h3>Refund Policy</h3>
                <h6>Eligibility</h6>
                <ul>
                  <li style={{textAlign:"justify"}}>
                    Refunds are available for damaged or incorrect items
                    reported within 7 days of receipt.
                  </li>
                </ul>{" "}
                <h6>Process</h6>
                <ul>
                  <li style={{textAlign:"justify"}}>
                    Email sai.abhi18healthcare@gmail.com with your order details
                    and photos of the issue.
                  </li>
                  <li style={{textAlign:"justify"}}>
                    Approved refunds will be processed within 10-15 business
                    days to your original payment method.
                  </li>
                </ul>{" "}
                <h6>Non-Refundable Items</h6>
                <ul>
                  <li style={{textAlign:"justify"}}>
                    Used, opened, or non-original condition items are not
                    eligible for refunds.
                  </li>
                  <li style={{textAlign:"justify"}}>Sale items and gift cards are non-refundable.</li>
                </ul>
              </div>
              <div class="single-content">
                <h3>Return Shipping</h3>
                <h6>Costs</h6>
                <ul>
                  <li style={{textAlign:"justify"}}>
                    Customers pay return shipping unless the return is due to
                    our error (incorrect or damaged item).
                  </li>
                  <li style={{textAlign:"justify"}}>Use a trackable shipping service for returns.</li>
                </ul>
              </div>
              <div class="single-content">
                <h3> Address</h3>

                <p>
                  66, Jinna Nagar,
                  <br />
                  Udhayamampattu Road,
                  <br />
                  Near Boys Higher Secondary School,
                  <br />
                  Thiyagadurgam (P.O), Kallakurichi - 606202
                </p>
              </div>
              <div class="single-content">
                <h3>Contact Us</h3>
                <p>For assistance, email sai.abhi18healthcare@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RefundandReturn;
