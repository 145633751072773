import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalLoderAction } from "../../Redux/Action";
import { BsCaretRightFill } from "react-icons/bs";
import blog1 from "../../assets/image/blog1.jpg";

function Blog1Details() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="blog-details-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="blog-details-content">
                <div class="blog-preview-img">
                  <img src={blog1} alt="Blog Details" />
                </div>
                <p style={{textAlign:"justify"}}>
                  Hey there! We all love to smell good, right? Each of us has
                  our favorite scents, and for many, there's nothing quite like
                  the divine fragrance of roses. That sweet, rosy scent is so
                  captivating and regal, it truly makes you stand out. However,
                  in our quest to smell as enchanting as a queen of roses, many
                  of us resort to chemically laden perfumes and deodorants,
                  often unaware of the long-term harm they can cause. In this
                  blog, we're excited to introduce a natural alternative to
                  these expensive and toxic products.
                </p>
                <h4 style={{ color: "#6B9246" }}>
                  Why Choose Natural Fragrances?
                </h4>
                <p style={{textAlign:"justify"}}>
                  Traditional perfumes and deodorants are often loaded with
                  chemicals like phthalates, parabens, and artificial
                  fragrances, which can irritate the skin, trigger allergies,
                  and disrupt our hormonal balance. Beyond their initial allure,
                  these products come at a hidden cost—polluting our bodies and
                  the environment.
                </p>{" "}
                <p style={{textAlign:"justify"}}>
                  These harmful chemicals seep into our surroundings,
                  contributing to air and water pollution, and impacting the
                  delicate balance of our ecosystems. At Sai Abhi Organic Care,
                  we believe that smelling good shouldn't compromise your health
                  or our planet. That's why we offer a better solution—one that
                  harnesses the power of nature while prioritizing your
                  well-being.
                </p>
                <blockquote class="blockquote">
                  <img
                    src="assets/images/blogs/blockquote.png"
                    alt="blockquote"
                  />
                  <p style={{textAlign:"justify"}}>
                    <b>
                      {" "}
                      Embrace Nature's Freshness with Our Organic Alternatives
                    </b>
                    <br />
                  </p>
                  <p style={{textAlign:"justify"}}>
                    In response to growing concerns about synthetic fragrances,
                    we proudly present Sai Abhi Organic Care's Rose Deo Stick.
                    Crafted from pure, organic ingredients and infused with the
                    essence of Bulgarian roses, our deodorant promises a
                    chemical-free experience. Picture the gentle, sweet scent of
                    fresh roses enveloping you, keeping you feeling fresh and
                    confident throughout the day.
                  </p>
                  <p style={{textAlign:"justify"}}>
                    What sets Sai Abhi Organic Care apart is our commitment to
                    purity. Our products are free from parabens, sulfates,
                    synthetic fragrances, and artificial preservatives, ensuring
                    they're gentle on your skin and kind to the environment. By
                    choosing our Rose Deo Stick, you're not just choosing a
                    delightful fragrance—you're making a conscious decision for
                    your health and the sustainability of our planet.
                  </p>

                  <p style={{textAlign:"justify"}}>
                    Experience the beauty of nature with every application, and
                    discover a new way to smell great without compromise. Join
                    us in embracing natural freshness, and let Sai Abhi Organic
                    Care redefine your fragrance routine.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Blog1Details;
