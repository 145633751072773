import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faqs, globalLoderAction } from "../Redux/Action";
import image from "../assets/image/4.jpg";

function FAQ() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(faqs())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, []);

  const faqquest = useSelector(({ faqs }) => faqs.payload);
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>FAQ</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#000" }}>FAQ</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="faq-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            {Array.isArray(faqquest) && faqquest.length !== 0 ? (
              faqquest.map((F, index) => (
                <div class="col-lg-12">
                   
                  <div class="faq-accordion">
                    <ul class="accordion">
                      <li
                        className={`accordion-item ${
                          index === activeIndex ? "active" : ""
                        }`}
                        key={index}
                      >
                        <a
                          className="accordion-title"
                          href="javascript:void(0)"
                          onClick={() => handleAccordionClick(index)}
                        >
                          <i
                            className={`bx ${
                              index === activeIndex ? "bx-minus" : "bx-plus"
                            }`}
                          ></i>
                          <div className="number">{index + 1}</div>
                          {F.title}
                        </a>

                        <div
                          className={`accordion-content ${
                            index === activeIndex ? "show" : ""
                          }`}
                        >
                          <p>{F.answer}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3>No FAQ Found!</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default FAQ;
